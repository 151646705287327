import React, {createContext, useState} from 'react';

export const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [count, setCount] = useState(0);

    const updateAuthStatus = (nftCount) => {
        setIsAuthenticated(nftCount > 0);
        setCount(nftCount);
    };

    return (
        <AuthContext.Provider value={{isAuthenticated, updateAuthStatus, count, setCount}}>
            {children}
        </AuthContext.Provider>
    );
};
